<template>
  <div>
    <a-card :bordered="false">
      <a-form-model :model="searchObj" layout="inline">
        <a-form-model-item label="车牌号">
          <a-input v-model="searchObj.plateNumber" placeholder="请输入车牌号"/>
        </a-form-model-item>
        <a-form-model-item label="订单状态">
          <a-select :default-value="searchObj.status" style="width: 150px" @change="onChangeOrderStatus">
            <a-select-option :value="99">
              全部
            </a-select-option>
            <a-select-option :value="0">
              待支付
            </a-select-option>
            <a-select-option :value="1">
              待写卡
            </a-select-option>
            <a-select-option :value="2">
              充值成功
            </a-select-option>
            <a-select-option :value="3">
              订单取消
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="订单时间">
          <a-range-picker
            style="width: 350px"
            :locale="locale"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
            :default-value="[moment(this.searchObj.beginTime, dateFormat), moment(this.searchObj.endTime, dateFormat)]"
            @change="onChangeTime"/>
        </a-form-model-item>
        <a-form-model-item style="margin-left: 15px">
          <a-button type="primary" @click="submitGet">查询</a-button>
        </a-form-model-item>
        <a-form-model-item style="margin-left: 15px">
          <a-button type="primary" @click="downloadFile">导出</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <a-card :bordered="false" style="margin-top: 10px">
      <a-table :locale="emptyText" :columns="tableColumns" :data-source="tableData" :pagination="pagination" @change="changeTabel"></a-table>
    </a-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
export default {
  name: "index",
  data() {
    return {
      emptyText: { emptyText: "暂无数据" },
      locale: locale,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      pagination: false,
      searchObj: {
        page: 1,
        pageSize: 10,
        beginTime: null,
        endTime: null,
        plateNumber: '',
        status: 99 //0-待支付,1-待写卡,2-充值成功,3-订单取消 99 全部
      },
      tableColumns: [
        {
          title: "订单编号",
          dataIndex: "orderNo"
        },
        {
          title: "下单时间",
          dataIndex: "createTimeDesc"
        },
        {
          title: "车牌号",
          dataIndex: "plateNumber"
        },
        {
          title: "ETC卡号",
          dataIndex: "etcNo"
        },
        {
          title: "充值金额(元)",
          dataIndex: "amount"
        },
        {
          title: "订单状态",
          dataIndex: "orderStatusDesc"
        },
        {
          title: "支付渠道",
          dataIndex: "payMethodDesc"
        }
      ],
      tableData: []
    }
  },
  methods: {
    moment,
    /*选择状态*/
    onChangeOrderStatus(e) {
      this.searchObj.status = e
    },
    /*选择时间*/
    onChangeTime(e,arrStr) {
      this.searchObj.beginTime = arrStr[0]
      this.searchObj.endTime = arrStr[1]
    },
    /*提交*/
    submitGet() {
      this.searchObj.page=1
      this.searchObj.pageSize=10
      this.selectList()
    },
    /*查询订单*/
    selectList() {
      let obj = {
        page: this.searchObj.page,
        pageSize: this.searchObj.pageSize,
        beginTime: this.searchObj.beginTime,
        endTime: this.searchObj.endTime,
        plateNumber: this.searchObj.plateNumber,
        status: this.searchObj.status
      }
      if(this.searchObj.status === 99) {
        obj.status = ''
      }
      let self = this;
      this.$http.postV("/depositCompany/queryDepositOrder", obj).then(res => {
        if (res.code === 200) {
          self.tableData = res.data.list;
          self.pagination = { total: parseInt(res.data.total) ,current:self.searchObj.page};
        }
      });
    },
    /*分页*/
    changeTabel(e) {
      this.searchObj.page = e.current;
      this.searchObj.pageSize = e.pageSize;
      this.selectList();
    },
    /*下载*/
    downloadFile() {
      let self=this;
      let obj = {
        page: self.searchObj.page,
        pageSize: self.searchObj.pageSize,
        plateNumber: self.searchObj.plateNumber,
        endTime: self.searchObj.endTime,
        beginTime:self.searchObj.beginTime,
        status: self.searchObj.status
      };
      this.$http.downLoadExcel("/depositCompany/exportDepositOrder", obj).then(res => {
        console.log(res);
      });

      /*let self=this;
      let data = {
        page: self.searchObj.page,
        pageSize: self.searchObj.pageSize,
        plateNumber: self.searchObj.plateNumber,
        endTime: self.searchObj.endTime,
        beginTime:self.searchObj.beginTime,
        status: self.searchObj.status
      };
      let qsData = qs.stringify(data)
      axios
        .post(
          "http://118.31.120.41:9081/depositCompany/exportDepositOrder",qsData,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "x-access-token": getToken(),
            },
          }
        )
        .then((res) => {
          downloadFn(res.data);
        });
      const downloadFn = (flow = null) => {
        if (!flow) return;
        const blob = new Blob([flow]);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.download = "ETC充值记录.xlsx"; // 自定义下载的文件名
        a.href = blobUrl;
        a.click();
      };*/
    }
  },
  created() {
    this.searchObj.beginTime = dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00";
    this.searchObj.endTime = dayjs(new Date()).format("YYYY-MM-DD") + " 23:59:59";
    this.selectList()
  }
};
</script>

<style scoped>

</style>